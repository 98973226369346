import { useTranslation } from "react-i18next";
import { FirstInterviewResult, SecondInterviewResult } from "../../../../models/application";
import React from "react";
import { Button, Form, Input, Space, Typography, Layout, Select, InputNumber, Row, Col, Collapse, Divider, Statistic } from "antd";
import { useForm } from "antd/es/form/Form";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { settingService } from "../../../../services/setting";
const { Header, Content, Footer, Sider } = Layout;

export function SecondRoundInterviewResultPage(props: { form?: SecondInterviewResult | string, onUpdate: (applicationForm: SecondInterviewResult) => void }) {
    const { t } = useTranslation();
    const [modify, setModify] = React.useState(true);
    const [f1] = useForm();

    const [secondInResultPartAPersonalityAbilitiesNegative, setSecondInResultPartAPersonalityAbilitiesNegative] = React.useState<string[]>([]);
    const [secondInResultPartAPersonalityAbilitiesPositive, setSecondInResultPartAPersonalityAbilitiesPositive] = React.useState<string[]>([]);
    const [secondInResultPartAPersonalityAppearancesNegative, setSecondInResultPartAPersonalityAppearancesNegative] = React.useState<string[]>([]);
    const [secondInResultPartAPersonalityAppearancesPositive, setSecondInResultPartAPersonalityAppearancesPositive] = React.useState<string[]>([]);
    const [secondInResultPartARemark, setSecondInResultPartARemark] = React.useState("");
    const [secondInResultPartAScoreOral, setSecondInResultPartAScoreOral] = React.useState<number>();
    const [secondInResultPartAScoreAttitude, setSecondInResultPartAScoreAttitude] = React.useState<number>();
    const [secondInResultPartAScorePutonghua, setSecondInResultPartAScorePutonghua] = React.useState<number>();
    const [secondInResultPartAScoreLifeExp, setSecondInResultPartAScoreLifeExp] = React.useState<number>();
    const [secondInResultPartAScoreEnglish, setSecondInResultPartAScoreEnglish] = React.useState<number>();
    const [secondInResultPartAScoreStory, setSecondInResultPartAScoreStory] = React.useState<number>();
    const [secondInResultPartASkill, setSecondInResultPartASkill] = React.useState<string[]>([]);
    const [SecondInResultPartATotalScore, setSecondInResultPartATotalScore] = React.useState<number>(0);

    const [secondInResultPartBLanguageNegative, setSecondInResultPartBLanguageNegative] = React.useState<string[]>([]);
    const [secondInResultPartBLanguagePositive, setSecondInResultPartBLanguagePositive] = React.useState<string[]>([]);
    const [secondInResultPartBRemark, setSecondInResultPartBRemark] = React.useState<string>("");
    const [secondInResultPartBScoreEng, setSecondInResultPartBScoreEng] = React.useState<number>();
    const [secondInResultPartBScoreChi, setSecondInResultPartBScoreChi] = React.useState<number>();
    const [secondInResultPartBScoreQ1Eng, setSecondInResultPartBScoreQ1Eng] = React.useState<number>();
    const [secondInResultPartBScoreQ1Chi, setSecondInResultPartBScoreQ1Chi] = React.useState<number>();
    const [secondInResultPartBScoreQ2Eng, setSecondInResultPartBScoreQ2Eng] = React.useState<number>();
    const [secondInResultPartBScoreQ2Chi, setSecondInResultPartBScoreQ2Chi] = React.useState<number>();
    const [secondInResultPartBScoreQ3Eng, setSecondInResultPartBScoreQ3Eng] = React.useState<number>();
    const [secondInResultPartBScoreQ3Chi, setSecondInResultPartBScoreQ3Chi] = React.useState<number>();
    const [personalityAbilitiesPositiveOptions, setPersonalityAbilitiesPositiveOptions] = React.useState<string[]>([]);
    const [personalityAbilitiesNegativeOptions, setPersonalityAbilitiesNegativeOptions] = React.useState<string[]>([]);
    const [personalityAppearancesPositiveOptions, setPersonalityAppearancesPositiveOptions] = React.useState<string[]>([]);
    const [personalityAppearancesNegativeOptions, setPersonalityAppearancesNegativeOptions] = React.useState<string[]>([]);
    const [positiveLanguageOptions, setPositiveLanguageOptions] = React.useState<string[]>([]);
    const [negativeLanguageOptions, setNegativeLanguageOptions] = React.useState<string[]>([]);
    const [skillOptions, setSkillOptions] = React.useState<string[]>([]);

    React.useEffect(() => {
        settingService.getHashTags().then(res => {
            if (res.status == "OK") {
                setPersonalityAbilitiesPositiveOptions(res.reference.PositivePersonalityAbilities)
                setPersonalityAbilitiesNegativeOptions(res.reference.NegativePersonalityAbilities)
                setPersonalityAppearancesPositiveOptions(res.reference.PositivePersonalityAppearances)
                setPersonalityAppearancesNegativeOptions(res.reference.NegativePersonalityAppearances)
                setPositiveLanguageOptions(res.reference.PositiveLanguage)
                setNegativeLanguageOptions(res.reference.NegativeLanguage)
                setSkillOptions(res.reference.Skill)
            }
        })
    }, [])

    React.useEffect(() => {
        resetForm();
    }, [props.form])
    function resetForm() {
        if (props.form && typeof props.form === 'object') {
            setSecondInResultPartAPersonalityAbilitiesNegative(props.form.SecondInResultPartAPersonalityAbilitiesNegative)
            setSecondInResultPartAPersonalityAbilitiesPositive(props.form.SecondInResultPartAPersonalityAbilitiesPositive)
            setSecondInResultPartAPersonalityAppearancesNegative(props.form.SecondInResultPartAPersonalityAppearancesNegative)
            setSecondInResultPartAPersonalityAppearancesPositive(props.form.SecondInResultPartAPersonalityAppearancesPositive)
            setSecondInResultPartARemark(props.form.SecondInResultPartARemark)
            setSecondInResultPartAScoreOral(props.form.SecondInResultPartAScoure["口語表達"])
            setSecondInResultPartAScoreAttitude(props.form.SecondInResultPartAScoure["態度舉止"])
            setSecondInResultPartAScorePutonghua(props.form.SecondInResultPartAScoure["普通話能力"])
            setSecondInResultPartAScoreLifeExp(props.form.SecondInResultPartAScoure["生活體驗"])
            setSecondInResultPartAScoreEnglish(props.form.SecondInResultPartAScoure["英語能力"])
            setSecondInResultPartAScoreStory(props.form.SecondInResultPartAScoure["講故事"])

            setSecondInResultPartATotalScore(
                (parseFloat(props.form.SecondInResultPartAScoure["口語表達"] + "") || 0) +
                (parseFloat(props.form.SecondInResultPartAScoure["態度舉止"] + "") || 0) +
                (parseFloat(props.form.SecondInResultPartAScoure["普通話能力"] + "") || 0) +
                (parseFloat(props.form.SecondInResultPartAScoure["生活體驗"] + "") || 0) +
                (parseFloat(props.form.SecondInResultPartAScoure["英語能力"] + "") || 0) +
                (parseFloat(props.form.SecondInResultPartAScoure["講故事"] + "") || 0)
            )

            setSecondInResultPartASkill(props.form.SecondInResultPartASkill)
            setSecondInResultPartBLanguageNegative(props.form.SecondInResultPartBLanguageNegative)
            setSecondInResultPartBLanguagePositive(props.form.SecondInResultPartBLanguagePositive)
            setSecondInResultPartBRemark(props.form.SecondInResultPartBRemark)
            setSecondInResultPartBScoreEng(
                (parseFloat(props.form.SecondInResultPartBScoure.Q1["English"] + "") || 0) +
                (parseFloat(props.form.SecondInResultPartBScoure.Q2["English"] + "") || 0) +
                (parseFloat(props.form.SecondInResultPartBScoure.Q3["English"] + "") || 0)
            )
            setSecondInResultPartBScoreChi(
                (parseFloat(props.form.SecondInResultPartBScoure.Q1["中文"] + "") || 0) +
                (parseFloat(props.form.SecondInResultPartBScoure.Q2["中文"] + "") || 0) +
                (parseFloat(props.form.SecondInResultPartBScoure.Q3["中文"] + "") || 0)
            )
            setSecondInResultPartBScoreQ1Eng(props.form.SecondInResultPartBScoure.Q1["English"])
            setSecondInResultPartBScoreQ1Chi(props.form.SecondInResultPartBScoure.Q1["中文"])
            setSecondInResultPartBScoreQ2Eng(props.form.SecondInResultPartBScoure.Q2["English"])
            setSecondInResultPartBScoreQ2Chi(props.form.SecondInResultPartBScoure.Q2["中文"])
            setSecondInResultPartBScoreQ3Eng(props.form.SecondInResultPartBScoure.Q3["English"])
            setSecondInResultPartBScoreQ3Chi(props.form.SecondInResultPartBScoure.Q3["中文"])
            f1.setFieldsValue({
                secondInResultPartAPersonalityAbilitiesNegative: props.form.SecondInResultPartAPersonalityAbilitiesNegative,
                secondInResultPartAPersonalityAbilitiesPositive: props.form.SecondInResultPartAPersonalityAbilitiesPositive,
                secondInResultPartAPersonalityAppearancesNegative: props.form.SecondInResultPartAPersonalityAppearancesNegative,
                secondInResultPartAPersonalityAppearancesPositive: props.form.SecondInResultPartAPersonalityAppearancesPositive,
                secondInResultPartARemark: props.form.SecondInResultPartARemark,
                secondInResultPartAScoreOral: props.form.SecondInResultPartAScoure["口語表達"],
                secondInResultPartAScoreAttitude: props.form.SecondInResultPartAScoure["態度舉止"],
                secondInResultPartAScorePutonghua: props.form.SecondInResultPartAScoure["普通話能力"],
                secondInResultPartAScoreLifeExp: props.form.SecondInResultPartAScoure["生活體驗"],
                secondInResultPartAScoreEnglish: props.form.SecondInResultPartAScoure["英語能力"],
                secondInResultPartAScoreStory: props.form.SecondInResultPartAScoure["講故事"],
                secondInResultPartASkill: props.form.SecondInResultPartASkill,
                secondInResultPartBLanguageNegative: props.form.SecondInResultPartBLanguageNegative,
                secondInResultPartBLanguagePositive: props.form.SecondInResultPartBLanguagePositive,
                secondInResultPartBRemark: props.form.SecondInResultPartBRemark,
                secondInResultPartBScoreQ1Eng: props.form.SecondInResultPartBScoure.Q1["English"],
                secondInResultPartBScoreQ1Chi: props.form.SecondInResultPartBScoure.Q1["中文"],
                secondInResultPartBScoreQ2Eng: props.form.SecondInResultPartBScoure.Q2["English"],
                secondInResultPartBScoreQ2Chi: props.form.SecondInResultPartBScoure.Q2["中文"],
                secondInResultPartBScoreQ3Eng: props.form.SecondInResultPartBScoure.Q3["English"],
                secondInResultPartBScoreQ3Chi: props.form.SecondInResultPartBScoure.Q3["中文"]
            });
        } else {
            setSecondInResultPartAPersonalityAbilitiesNegative([])
            setSecondInResultPartAPersonalityAbilitiesPositive([])
            setSecondInResultPartAPersonalityAppearancesNegative([])
            setSecondInResultPartAPersonalityAppearancesPositive([])
            setSecondInResultPartARemark("")
            setSecondInResultPartAScoreOral(undefined)
            setSecondInResultPartAScoreAttitude(undefined)
            setSecondInResultPartAScorePutonghua(undefined)
            setSecondInResultPartAScoreLifeExp(undefined)
            setSecondInResultPartAScoreEnglish(undefined)
            setSecondInResultPartAScoreStory(undefined)
            setSecondInResultPartASkill([])
            setSecondInResultPartBLanguageNegative([])
            setSecondInResultPartBLanguagePositive([])
            setSecondInResultPartBRemark("")
            setSecondInResultPartBScoreEng(undefined)
            setSecondInResultPartBScoreChi(undefined)
            setSecondInResultPartBScoreQ1Eng(undefined)
            setSecondInResultPartBScoreQ1Chi(undefined)
            setSecondInResultPartBScoreQ2Eng(undefined)
            setSecondInResultPartBScoreQ2Chi(undefined)
            setSecondInResultPartBScoreQ3Eng(undefined)
            setSecondInResultPartBScoreQ3Chi(undefined)
            f1.setFieldsValue({
                secondInResultPartAPersonalityAbilitiesNegative: [],
                secondInResultPartAPersonalityAbilitiesPositive: [],
                secondInResultPartAPersonalityAppearancesNegative: [],
                secondInResultPartAPersonalityAppearancesPositive: [],
                secondInResultPartARemark: "",
                secondInResultPartAScoreOral: "",
                secondInResultPartAScoreAttitude: "",
                secondInResultPartAScorePutonghua: "",
                secondInResultPartAScoreLifeExp: "",
                secondInResultPartAScoreEnglish: "",
                secondInResultPartAScoreStory: "",
                secondInResultPartASkill: [],
                secondInResultPartBLanguageNegative: [],
                secondInResultPartBLanguagePositive: [],
                secondInResultPartBRemark: "",
                secondInResultPartBScoreQ1Eng: "",
                secondInResultPartBScoreQ1Chi: "",
                secondInResultPartBScoreQ2Eng: "",
                secondInResultPartBScoreQ2Chi: "",
                secondInResultPartBScoreQ3Eng: "",
                secondInResultPartBScoreQ3Chi: ""
            });
        }

    }
    return <>
        <Content style={{ display: "flex", alignContent: "space-between", marginBottom: 30 }}>
            <p style={{ display: "flex", flex: 1, fontStyle: "italic" }}>{t("*If the form is not loaded correctly, please press the Reset button")}</p>
            <Button.Group>
                {!modify && <Button onClick={() => {
                    setModify(true);
                    f1.submit()
                }} type='primary'>{t("Save & Update")}</Button>}
                {modify && <Button onClick={() => {
                    setModify(false);
                }}>{t("Edit")}</Button>}
                <Button onClick={() => {
                    resetForm();
                    setModify(true);
                }} >{t("Reset")}</Button>
            </Button.Group>
        </Content>
        <Form disabled={modify} name="firstRoundInterview" layout="vertical" form={f1}
            initialValues={{
                secondInResultPartAPersonalityAbilitiesNegative,
                secondInResultPartAPersonalityAbilitiesPositive,
                secondInResultPartAPersonalityAppearancesNegative,
                secondInResultPartAPersonalityAppearancesPositive,
                secondInResultPartARemark,
                secondInResultPartAScoreOral,
                secondInResultPartAScoreAttitude,
                secondInResultPartAScorePutonghua,
                secondInResultPartAScoreLifeExp,
                secondInResultPartAScoreEnglish,
                secondInResultPartAScoreStory,
                secondInResultPartASkill,
                secondInResultPartBLanguageNegative,
                secondInResultPartBLanguagePositive,
                secondInResultPartBRemark,
                secondInResultPartBScoreQ1Eng,
                secondInResultPartBScoreQ1Chi,
                secondInResultPartBScoreQ2Eng,
                secondInResultPartBScoreQ2Chi,
                secondInResultPartBScoreQ3Eng,
                secondInResultPartBScoreQ3Chi
            }}
            onFinish={(values: any) => {
                let form = {
                    SecondInResultPartAPersonalityAbilitiesNegative: values.secondInResultPartAPersonalityAbilitiesNegative,
                    SecondInResultPartAPersonalityAbilitiesPositive: values.secondInResultPartAPersonalityAbilitiesPositive,
                    SecondInResultPartAPersonalityAppearancesNegative: values.secondInResultPartAPersonalityAppearancesNegative,
                    SecondInResultPartAPersonalityAppearancesPositive: values.secondInResultPartAPersonalityAppearancesPositive,
                    SecondInResultPartARemark: values.secondInResultPartARemark,
                    SecondInResultPartAScoure: {
                        "口語表達": values.secondInResultPartAScoreOral,
                        "態度舉止": values.secondInResultPartAScoreAttitude,
                        "普通話能力": values.secondInResultPartAScorePutonghua,
                        "生活體驗": values.secondInResultPartAScoreLifeExp,
                        "英語能力": values.secondInResultPartAScoreEnglish,
                        "講故事": values.secondInResultPartAScoreStory
                    },
                    SecondInResultPartASkill: values.secondInResultPartASkill,
                    SecondInResultPartASkillOther: "",
                    SecondInResultPartBLanguageNegative: values.secondInResultPartBLanguageNegative,
                    SecondInResultPartBLanguagePositive: values.secondInResultPartBLanguagePositive,
                    SecondInResultPartBRemark: values.secondInResultPartBRemark,
                    SecondInResultPartBScoure: {
                        "Eng": values.secondInResultPartBScoreQ1Eng + values.secondInResultPartBScoreQ2Eng + values.secondInResultPartBScoreQ3Eng,//values.secondInResultPartBScoreEng,
                        "中": values.secondInResultPartBScoreQ1Chi + values.secondInResultPartBScoreQ2Chi + values.secondInResultPartBScoreQ3Chi, //values.secondInResultPartBScoreChi,
                        Q1: {
                            "English": values.secondInResultPartBScoreQ1Eng,
                            "中文": values.secondInResultPartBScoreQ1Chi
                        },
                        Q2: {
                            "English": values.secondInResultPartBScoreQ2Eng,
                            "中文": values.secondInResultPartBScoreQ2Chi
                        },
                        Q3: {
                            "English": values.secondInResultPartBScoreQ3Eng,
                            "中文": values.secondInResultPartBScoreQ3Chi
                        }
                    }
                }
                props.onUpdate(form)
            }}>
            <Row>
                <Col className="gutter-row" md={4} sm={6} xs={12}>
                    <Statistic title={t("Part A Score")} value={(SecondInResultPartATotalScore || 0)} />
                </Col>
                <Col className="gutter-row" md={4} sm={2} xs={12}>
                    <Statistic title={"       "} value={"+"} />
                </Col>
                <Col className="gutter-row" md={4} sm={6} xs={12}>
                    <Statistic title={t("Part B Score")} value={(secondInResultPartBScoreChi || 0) + (secondInResultPartBScoreEng || 0)} />
                </Col>
                <Col className="gutter-row" md={4} sm={2} xs={12}>
                    <Statistic title={"       "} value={"="} />
                </Col>
                <Col className="gutter-row" md={8} sm={8} xs={24}>
                    <Statistic title={t("Total Score")} value={(SecondInResultPartATotalScore || 0) + (secondInResultPartBScoreChi || 0) + (secondInResultPartBScoreEng || 0)} />
                </Col>
                <Col className="gutter-row" md={24} sm={24} xs={24} style={{ marginTop: 12 }}>
                    <Form.Item label={t("Remark")} name={"secondInResultPartBRemark"}>
                        <TextArea rows={4} />
                    </Form.Item>
                </Col>
            </Row>
            <Collapse collapsible={"header"} defaultActiveKey={['InterviewSecionA']} items={[
                {
                    key: 'InterviewSecionA',
                    label: <>{t("Section A")}</>,
                    extra: <>{t("Total") + ": " + SecondInResultPartATotalScore}</>,
                    children: <>
                        <Row gutter={16}>
                            <Col className="gutter-row" md={12} sm={24} xs={24}>
                                <Form.Item label={t("Appearances Positive")} name={"secondInResultPartAPersonalityAppearancesPositive"}>
                                    <Select
                                        mode="tags"
                                        style={{ width: '100%' }}
                                        options={personalityAppearancesPositiveOptions.map((d: any) => {
                                            return { value: d, label: d }
                                        })}
                                    />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" md={12} sm={24} xs={24}>
                                <Form.Item label={t("Apperances Negative")} name={"secondInResultPartAPersonalityAppearancesNegative"}>
                                    <Select
                                        mode="tags"
                                        style={{ width: '100%' }}
                                        options={personalityAppearancesNegativeOptions.map((d: any) => {
                                            return { value: d, label: d }
                                        })}
                                    />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" md={12} sm={24} xs={24}>
                                <Form.Item label={t("Abilities Positive")} name={"secondInResultPartAPersonalityAbilitiesPositive"}>
                                    <Select
                                        mode="tags"
                                        style={{ width: '100%' }}
                                        options={personalityAbilitiesPositiveOptions.map((d: any) => {
                                            return { value: d, label: d }
                                        })}
                                    />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" md={12} sm={24} xs={24}>
                                <Form.Item label={t("Abilities Negative")} name={"secondInResultPartAPersonalityAbilitiesNegative"}>
                                    <Select
                                        mode="tags"
                                        style={{ width: '100%' }}
                                        options={personalityAbilitiesNegativeOptions.map((d: any) => {
                                            return { value: d, label: d }
                                        })}
                                    />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" md={24} sm={24} xs={24}>
                                <Form.Item label={t("Remark")} name={"secondInResultPartARemark"}>
                                    <TextArea rows={4} />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" md={8} sm={24} xs={24}>
                                <Form.Item label={t("Oral Expression")} name={"secondInResultPartAScoreOral"}>
                                    <InputNumber
                                        min={0}
                                        max={100}
                                        onChange={(e) => {
                                            setSecondInResultPartATotalScore(
                                                (parseFloat(f1.getFieldValue("secondInResultPartAScoreOral")) || 0)
                                                + (parseFloat(f1.getFieldValue("secondInResultPartAScorePutonghua")) || 0)
                                                + (parseFloat(f1.getFieldValue("secondInResultPartAScoreEnglish")) || 0)
                                                + (parseFloat(f1.getFieldValue("secondInResultPartAScoreAttitude")) || 0)
                                                + (parseFloat(f1.getFieldValue("secondInResultPartAScoreLifeExp")) || 0)
                                                + (parseFloat(f1.getFieldValue("secondInResultPartAScoreStory")) || 0)
                                            )
                                        }}
                                        style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" md={8} sm={24} xs={24}>
                                <Form.Item label={t("Mandarin Proficiency")} name={"secondInResultPartAScorePutonghua"}>
                                    <InputNumber
                                        min={0}
                                        max={100}
                                        onChange={(e) => {
                                            setSecondInResultPartATotalScore(
                                                (parseFloat(f1.getFieldValue("secondInResultPartAScoreOral")) || 0)
                                                + (parseFloat(f1.getFieldValue("secondInResultPartAScorePutonghua")) || 0)
                                                + (parseFloat(f1.getFieldValue("secondInResultPartAScoreEnglish")) || 0)
                                                + (parseFloat(f1.getFieldValue("secondInResultPartAScoreAttitude")) || 0)
                                                + (parseFloat(f1.getFieldValue("secondInResultPartAScoreLifeExp")) || 0)
                                                + (parseFloat(f1.getFieldValue("secondInResultPartAScoreStory")) || 0)
                                            )
                                        }}
                                        style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" md={8} sm={24} xs={24}>
                                <Form.Item label={t("English Proficiency")} name={"secondInResultPartAScoreEnglish"}>
                                    <InputNumber
                                        min={0}
                                        max={100}
                                        onChange={(e) => {
                                            setSecondInResultPartATotalScore(
                                                (parseFloat(f1.getFieldValue("secondInResultPartAScoreOral")) || 0)
                                                + (parseFloat(f1.getFieldValue("secondInResultPartAScorePutonghua")) || 0)
                                                + (parseFloat(f1.getFieldValue("secondInResultPartAScoreEnglish")) || 0)
                                                + (parseFloat(f1.getFieldValue("secondInResultPartAScoreAttitude")) || 0)
                                                + (parseFloat(f1.getFieldValue("secondInResultPartAScoreLifeExp")) || 0)
                                                + (parseFloat(f1.getFieldValue("secondInResultPartAScoreStory")) || 0)
                                            )
                                        }}
                                        style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" md={8} sm={24} xs={24}>
                                <Form.Item label={t("Attitude and Demeanor")} name={"secondInResultPartAScoreAttitude"}>
                                    <InputNumber
                                        min={0}
                                        max={100}
                                        onChange={(e) => {
                                            setSecondInResultPartATotalScore(
                                                (parseFloat(f1.getFieldValue("secondInResultPartAScoreOral")) || 0)
                                                + (parseFloat(f1.getFieldValue("secondInResultPartAScorePutonghua")) || 0)
                                                + (parseFloat(f1.getFieldValue("secondInResultPartAScoreEnglish")) || 0)
                                                + (parseFloat(f1.getFieldValue("secondInResultPartAScoreAttitude")) || 0)
                                                + (parseFloat(f1.getFieldValue("secondInResultPartAScoreLifeExp")) || 0)
                                                + (parseFloat(f1.getFieldValue("secondInResultPartAScoreStory")) || 0)
                                            )
                                        }}
                                        style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" md={8} sm={24} xs={24}>
                                <Form.Item label={t("Life Experience")} name={"secondInResultPartAScoreLifeExp"}>
                                    <InputNumber
                                        min={0}
                                        max={100}
                                        onChange={(e) => {
                                            setSecondInResultPartATotalScore(
                                                (parseFloat(f1.getFieldValue("secondInResultPartAScoreOral")) || 0)
                                                + (parseFloat(f1.getFieldValue("secondInResultPartAScorePutonghua")) || 0)
                                                + (parseFloat(f1.getFieldValue("secondInResultPartAScoreEnglish")) || 0)
                                                + (parseFloat(f1.getFieldValue("secondInResultPartAScoreAttitude")) || 0)
                                                + (parseFloat(f1.getFieldValue("secondInResultPartAScoreLifeExp")) || 0)
                                                + (parseFloat(f1.getFieldValue("secondInResultPartAScoreStory")) || 0)
                                            )
                                        }}
                                        style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" md={8} sm={24} xs={24}>
                                <Form.Item label={t("Story Telling")} name={"secondInResultPartAScoreStory"}>
                                    <InputNumber
                                        min={0}
                                        max={100}
                                        onChange={(e) => {
                                            setSecondInResultPartATotalScore(
                                                (parseFloat(f1.getFieldValue("secondInResultPartAScoreOral")) || 0)
                                                + (parseFloat(f1.getFieldValue("secondInResultPartAScorePutonghua")) || 0)
                                                + (parseFloat(f1.getFieldValue("secondInResultPartAScoreEnglish")) || 0)
                                                + (parseFloat(f1.getFieldValue("secondInResultPartAScoreAttitude")) || 0)
                                                + (parseFloat(f1.getFieldValue("secondInResultPartAScoreLifeExp")) || 0)
                                                + (parseFloat(f1.getFieldValue("secondInResultPartAScoreStory")) || 0)
                                            )
                                        }}
                                        style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" md={24} sm={24} xs={24}>
                                <Form.Item label={t("Skill")} name={"secondInResultPartASkill"}>
                                    <Select
                                        mode="tags"
                                        style={{ width: '100%' }}
                                        options={skillOptions.map((d: any) => {
                                            return { value: d, label: d }
                                        })}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </>,
                },
            ]} />



            <Collapse collapsible={"header"} defaultActiveKey={['InterviewSecionB']} items={[
                {
                    key: 'InterviewSecionB',
                    label: t("Section B"),
                    extra: <>{t("Total") + ": " + ((secondInResultPartBScoreChi || 0) + (secondInResultPartBScoreEng || 0))}</>,
                    children: <>
                        <Row gutter={16}>

                            <Col className="gutter-row" md={12} sm={24} xs={24}>
                                <Form.Item label={t("Language Positive")} name={"secondInResultPartBLanguagePositive"}>
                                    <Select
                                        mode="tags"
                                        style={{ width: '100%' }}
                                        options={positiveLanguageOptions.map((d: any) => {
                                            return { value: d, label: d }
                                        })}
                                    />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" md={12} sm={24} xs={24}>
                                <Form.Item label={t("Language Negative")} name={"secondInResultPartBLanguageNegative"}>
                                    <Select
                                        mode="tags"
                                        style={{ width: '100%' }}
                                        options={negativeLanguageOptions.map((d: any) => {
                                            return { value: d, label: d }
                                        })}
                                    />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" md={12} sm={24} xs={24}>
                                <Form.Item label={t("Q1 Chinese")} name={"secondInResultPartBScoreQ1Chi"}>
                                    <InputNumber
                                        min={0}
                                        max={100}
                                        onChange={(e) => {
                                            setSecondInResultPartBScoreChi(
                                                (parseFloat(f1.getFieldValue("secondInResultPartBScoreQ1Chi")) || 0)
                                                + (parseFloat(f1.getFieldValue("secondInResultPartBScoreQ2Chi")) || 0)
                                                + (parseFloat(f1.getFieldValue("secondInResultPartBScoreQ3Chi")) || 0)
                                            )
                                        }}
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" md={12} sm={24} xs={24}>
                                <Form.Item label={t("Q1 English")} name={"secondInResultPartBScoreQ1Eng"}>
                                    <InputNumber
                                        min={0}
                                        max={100}
                                        onChange={(e) => {
                                            setSecondInResultPartBScoreEng(
                                                (parseFloat(f1.getFieldValue("secondInResultPartBScoreQ1Eng")) || 0)
                                                + (parseFloat(f1.getFieldValue("secondInResultPartBScoreQ2Eng")) || 0)
                                                + (parseFloat(f1.getFieldValue("secondInResultPartBScoreQ3Eng")) || 0)
                                            )
                                        }}
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" md={12} sm={24} xs={24}>
                                <Form.Item label={t("Q2 Chinese")} name={"secondInResultPartBScoreQ2Chi"}>
                                    <InputNumber
                                        min={0}
                                        max={100}
                                        onChange={(e) => {
                                            setSecondInResultPartBScoreChi(
                                                (parseFloat(f1.getFieldValue("secondInResultPartBScoreQ1Chi")) || 0)
                                                + (parseFloat(f1.getFieldValue("secondInResultPartBScoreQ2Chi")) || 0)
                                                + (parseFloat(f1.getFieldValue("secondInResultPartBScoreQ3Chi")) || 0)
                                            )
                                        }}
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" md={12} sm={24} xs={24}>
                                <Form.Item label={t("Q2 English")} name={"secondInResultPartBScoreQ2Eng"}>
                                    <InputNumber
                                        min={0}
                                        max={100}
                                        onChange={(e) => {
                                            setSecondInResultPartBScoreEng(
                                                (parseFloat(f1.getFieldValue("secondInResultPartBScoreQ1Eng")) || 0)
                                                + (parseFloat(f1.getFieldValue("secondInResultPartBScoreQ2Eng")) || 0)
                                                + (parseFloat(f1.getFieldValue("secondInResultPartBScoreQ3Eng")) || 0)
                                            )
                                        }}
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" md={12} sm={24} xs={24}>
                                <Form.Item label={t("Q3 Chinese")} name={"secondInResultPartBScoreQ3Chi"}>
                                    <InputNumber
                                        min={0}
                                        max={100}
                                        onChange={(e) => {

                                            setSecondInResultPartBScoreChi(
                                                (parseFloat(f1.getFieldValue("secondInResultPartBScoreQ1Chi")) || 0)
                                                + (parseFloat(f1.getFieldValue("secondInResultPartBScoreQ2Chi")) || 0)
                                                + (parseFloat(f1.getFieldValue("secondInResultPartBScoreQ3Chi")) || 0)
                                            )
                                        }}
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" md={12} sm={24} xs={24}>
                                <Form.Item label={t("Q3 English")} name={"secondInResultPartBScoreQ3Eng"}>
                                    <InputNumber
                                        min={0}
                                        max={100}
                                        onChange={(e) => {
                                            setSecondInResultPartBScoreEng(
                                                (parseFloat(f1.getFieldValue("secondInResultPartBScoreQ1Eng")) || 0)
                                                + (parseFloat(f1.getFieldValue("secondInResultPartBScoreQ2Eng")) || 0)
                                                + (parseFloat(f1.getFieldValue("secondInResultPartBScoreQ3Eng")) || 0)
                                            )

                                        }}
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" md={12} sm={24} xs={24}>
                                <Form.Item label={t("Chinese")} name={"secondInResultPartBScoreChi"}>
                                    {secondInResultPartBScoreChi}
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" md={12} sm={24} xs={24}>
                                <Form.Item label={t("English")} name={"secondInResultPartBScoreEng"}>
                                    {secondInResultPartBScoreEng}
                                </Form.Item>
                            </Col>

                        </Row>
                    </>,
                },
            ]} />

        </Form>

    </>
}